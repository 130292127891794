/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDateTimePicker, DateTimePicker, renderTimeViewClock, LocalizationProvider } from '@mui/x-date-pickers'
import { Box, StandardTextFieldProps, Typography } from '@mui/material'
import { Control, useController } from 'react-hook-form'

function makeDatePickerChangeHandler(onChange: (...event: any[]) => void, name: string) {
  return (newDate: Date | null): Promise<void> => {
    const triggerEvent = {
      target: {
        name,
        value: '',
      },
    }

    if (newDate) {
      triggerEvent.target.value = newDate.toString()
    }

    onChange(triggerEvent)
    return Promise.resolve()
  }
}

interface DateTimePickerProbs extends Omit<StandardTextFieldProps, 'error'> {
  mobilePicker?: boolean
  disablePast?: boolean
  disableFuture?: boolean
  required?: boolean
  name: string
  control: Control<any>
  label: string
}

function DateTimePickerComponent({
  mobilePicker,
  disablePast,
  disableFuture,
  required,
  name,
  control,
  label,
}: DateTimePickerProbs) {
  const {
    field: { onChange, onBlur },
    fieldState: { error },
  } = useController({ name, control })
  const changeHandler = React.useCallback(makeDatePickerChangeHandler(onChange, name), [name, onChange])

  return (
    <Box>
      <Typography sx={{ mb: 2, ml: '2px', fontWeight: 500, wordBreak: 'break-word' }}>
        {label} {required && <span className="required">*</span>}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {mobilePicker ? (
          <MobileDateTimePicker
            sx={{ width: '100%' }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            slotProps={{
              textField: { error: !!error, onBlur: () => onBlur },
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        ) : (
          <DateTimePicker
            sx={{ width: '100%' }}
            onChange={changeHandler}
            // format="yyyy-MM-ddThh:mm"
            slotProps={{
              textField: { error: !!error, onBlur: () => onBlur },
            }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        )}
      </LocalizationProvider>
    </Box>
  )
}

DateTimePickerComponent.defaultProps = {
  mobilePicker: false,
  disablePast: false,
  disableFuture: false,
  required: false,
}

export default DateTimePickerComponent
