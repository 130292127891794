import * as yup from './common/yup-extended.d'
import { Constant } from './constances'

export const SignSchema = yup.default.object({
  email: yup.default.string().required().matches(Constant.emailRegex),
  password: yup.default.string().required().min(6),
  rememberMe: yup.default.boolean(),
})

export type Sign = yup.default.InferType<typeof SignSchema>
