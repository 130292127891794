/* eslint-disable func-names */
// eslint-disable-next-line import/no-extraneous-dependencies
import { isValid, parseISO } from 'date-fns'
import * as yup from 'yup'
import { setLocale } from 'yup'
import i18n from '../../..'

function isValidPercent(value: string | number | null) {
  if (value !== null) {
    const dot = value.toString().replace(',', '.').indexOf('.')
    const decimal = value.toString().substring(dot)
    if (dot === -1) return true
    if (decimal.length <= 3) return true
  }
  return false
}

yup.addMethod<StringSchema>(yup.string, 'IsValidCanadianPostalCode', function (errorMessage) {
  return this.test('test-PostalCode-type', errorMessage, function (value) {
    const { path, createError } = this
    const exp = '^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$'
    if (value) {
      return (
        new RegExp(exp).test(this.parent.postalCode?.toUpperCase()) ||
        createError({ path, message: 'common.errors.postalCode' })
      )
    }
    return true
  })
})

yup.addMethod<NumberSchema>(yup.number, 'PercentType', function (errorMessage) {
  return this.test('test-Percent-type', errorMessage, function (value) {
    const { path, createError } = this

    return !value || isValidPercent(value) || createError({ path, message: 'common.errors.percentError' })
  })
})

yup.addMethod<StringSchema>(yup.string, 'IsValidCanadianPhone', function (errorMessage) {
  return this.test('test-CellPhone-type', errorMessage, function (value) {
    const { path, createError } = this
    const exp = '^[0-9]{3}?[-]?[0-9]{3}?[-]?[0-9]{4}'
    if (value) {
      return new RegExp(exp).test(value) || createError({ path, message: 'common.errors.phone' })
    }
    return true
  })
})

yup.addMethod<StringSchema>(yup.string, 'isValidDate', function (errorMessage) {
  return this.test('test-ValideDate', errorMessage, function (value) {
    const { path, createError } = this

    if (value) return isValid(parseISO(value)) || createError({ path, message: 'common.errors.date' })

    return true
  })
})

declare module 'yup' {
  interface StringSchema<
    TType extends yup.Maybe<string> = string | undefined,
    TContext extends yup.AnyObject = yup.AnyObject,
    TOut extends TType = TType,
  > extends yup.StringSchema<TType, TContext, TOut> {
    SinType(): StringSchema<TType, TContext>
    IsValidCanadianPostalCode(): StringSchema<TType, TContext>
    IsValidCanadianPhone(): StringSchema<TType, TContext>
    isValidDate(): StringSchema<TType, TContext>
  }
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.NumberSchema<TType, TContext, TOut> {
    PercentType(): NumberSchema<TType, TContext>
  }
}

export default yup

/**
 * Add basic error message
 */
setLocale({
  number: {
    min: ({ min }) => i18n.t('common.errors.greaterOrEqual', { min }),
    max: ({ max }) => i18n.t('common.errors.lowerOrEqual', { max }),
    positive: 'common.errors.positive',
  },
  string: {
    min: ({ min }) => i18n.t('common.errors.greaterOrEqual', { min }),
    max: ({ max }) => i18n.t('common.errors.lowerOrEqual', { max }),
  },
})
