import React from 'react'
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { Event, EventSchema } from '../../../data/types/eventSchema'
import { reportErrorToConsole } from '../../../utils/errorLogger'
import EventCapacityInput from './components/eventCapacity'
import GuestInput from './components/guests'
import Location from './components/location'
import { setDocument } from '../../../firebase'
import { Breacrumb, breadcrumb, DateTimePicker, InputTextField, InputFile, SingleSelect } from '../../components'

export default function CreateEvent() {
  const filesUploadRef = React.useRef()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
    getValues,
  } = useForm<Event>({
    mode: 'onBlur',
    defaultValues: EventSchema.getDefault(),
    resolver: yupResolver(EventSchema),
  })

  const onSubmit = async (data: Event) => {
    setDocument('/events', data).then(() => reset(EventSchema.getDefault()))
  }

  const {
    fields: eventCapacityFiels,
    remove: eventCapacityRemove,
    append: eventCapacityAppend,
  } = useFieldArray({ name: 'capacity', control })

  const { fields: guestsFiels, remove: guestRemove, append: guestAppend } = useFieldArray({ name: 'guests', control })

  const BREACRUMB: Array<breadcrumb> = [
    { path: '/event', label: 'Events' },
    { path: '#', label: 'Create Event' },
  ]

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, reportErrorToConsole)} sx={{ mt: 1 }}>
      <Breacrumb trees={BREACRUMB} />
      <Grid container justifyContent="center">
        {/* maxWidth: '752px', */}
        <Paper elevation={3} sx={{ width: '100%', padding: '40px' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography style={{ fontSize: '2em' }}>Create Event</Typography>
            <Typography style={{ fontSize: '1em' }}>Please provide event details</Typography>
          </Box>

          <Divider sx={{ mt: '2.5rem', mb: '2.5rem' }} />

          <Typography mt={3.5} fontWeight="bold" textAlign="center">
            All fields marked with * are required and must be filled.
          </Typography>

          <InputFile ref={filesUploadRef} required />

          <InputTextField id="title" label="Title" {...register('title')} error={errors.title} />

          {/* TODO: GET CATEGORY FROM DB */}
          <SingleSelect
            disableNone
            content={[
              { value: 'sport', label: 'Sport' },
              { value: 'music', label: 'Music' },
              { value: 'food', label: 'Food' },
            ]}
            label="Category"
            {...register('categoryId')}
            error={errors.categoryId}
          />

          <EventCapacityInput
            allowDelete={eventCapacityFiels.length > 1}
            eventCapacityFiels={eventCapacityFiels}
            register={register}
            errors={errors.capacity}
            onDelete={eventCapacityRemove}
            onAppend={eventCapacityAppend}
          />

          <GuestInput
            allowDelete={guestsFiels.length > 1}
            guestsFiels={guestsFiels}
            register={register}
            errors={errors.guests}
            onDelete={guestRemove}
            onAppend={guestAppend}
          />

          <Divider sx={{ mt: '2.5rem' }} />

          <Location errors={errors} register={register} setValue={setValue} getValues={getValues} />

          <Divider sx={{ mt: '2.5rem', mb: '2.5rem' }} />

          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} md={6}>
              <DateTimePicker name="startOn" control={control} disablePast label="Start on" />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker name="endOn" control={control} disablePast label="End on" />
            </Grid>
          </Grid>

          <Divider sx={{ mt: '2.5rem', mb: '2.5rem' }} />

          <InputTextField
            id="summary"
            label="Summary"
            {...register('summary')}
            error={errors.summary}
            minRows={3}
            maxRows={5}
            multiline
          />

          <InputTextField
            id="terms"
            label="Terms"
            {...register('terms')}
            error={errors.terms}
            multiline
            minRows={3}
            maxRows={5}
          />

          <Divider sx={{ mt: '2.5rem', mb: '2.5rem' }} />
          <Box textAlign="center">
            <Button type="submit" variant="contained" size="large">
              Submit
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Box>
  )
}
