import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRootState } from '..'

export type error = { code: number; message: string }
interface AppState {
  language: string
  isDarkMode: boolean
  loading: boolean
  message: string | undefined
  error: error | undefined
}

const initialState: AppState = {
  language: 'fr',
  isDarkMode: true,
  loading: false,
  message: undefined,
  error: undefined,
}

const PREFERS_DARK_SETTING = 'dark'

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loadPreferences: (state) => {
      const darkModePref = localStorage.getItem(PREFERS_DARK_SETTING)
      if (darkModePref) state.isDarkMode = darkModePref === 'true'
      else {
        state.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
      }
    },
    toogleDarkModePreference: (state) => {
      state.isDarkMode = !state.isDarkMode
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setSucessMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setError: (state, action: PayloadAction<error>) => {
      state.error = action.payload
    },
  },
})

export const appActions = appSlice.actions

export default appSlice.reducer

export const appSelector = {
  getLangue: (state: TRootState) => state.app.language,
  isDarkMode: (state: TRootState) => state.app.isDarkMode,
  isLoading: (state: TRootState) => state.app.loading,
  getMessage: (state: TRootState) => state.app.message,
  getError: (state: TRootState) => state.app.error,
}
