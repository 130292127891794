import { Box, Button, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import {
  FieldArrayWithId,
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
} from 'react-hook-form'
import {
  Groups as GroupsIcon,
  LocalOffer as LocalOfferIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material'
import { InputTextField } from '../../../components'
import { Event, EventCapacity } from '../../../../data/types/eventSchema'

type Props = {
  errors?: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<EventCapacity>> | undefined)[]>
  register: UseFormRegister<Event>
  eventCapacityFiels: FieldArrayWithId<Event, 'capacity', 'id'>[]
  onAppend: UseFieldArrayAppend<Event, 'capacity'>
  onDelete: UseFieldArrayRemove
  allowDelete: boolean
}
function EventCapacityInput({ errors, register, onDelete, allowDelete, eventCapacityFiels, onAppend }: Props) {
  const theme = useTheme()
  return (
    <Box sx={{ mt: '2.5rem' }}>
      <Typography sx={{ mb: 0, ml: '2px', fontWeight: 500, wordBreak: 'break-word' }}>
        Event Capacity <span className="required">*</span>
      </Typography>
      {eventCapacityFiels.map((capacity, index) => (
        <div key={capacity.id}>
          {index > 0 && <Divider sx={{ mt: '2.5rem' }} />}

          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} md={4}>
              <InputTextField
                label="Ticket Class"
                error={errors?.[index]?.ticketClass?.fr}
                {...register(`capacity.${index}.ticketClass.fr`)}
              />
            </Grid>
            <Grid item xs={12} md={allowDelete ? 3.5 : 4}>
              <InputTextField
                label="Capacity Total"
                error={errors?.[index]?.capacityTotal}
                {...register(`capacity.${index}.capacityTotal`)}
                InputProps={{
                  endAdornment: <GroupsIcon />,
                }}
              />
            </Grid>
            <Grid item xs={12} md={allowDelete ? 3.5 : 4}>
              <InputTextField
                label="Ticket Price"
                error={errors?.[index]?.ticketPrice}
                {...register(`capacity.${index}.ticketPrice`)}
                InputProps={{
                  endAdornment: <LocalOfferIcon />,
                }}
              />
            </Grid>

            {allowDelete && (
              <Grid item xs={12} md={1} textAlign="end">
                <IconButton
                  aria-label="delete"
                  sx={{
                    '&:hover': {
                      color: theme.palette.error.main,
                    },
                  }}
                  onClick={() => onDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </div>
      ))}
      <Box textAlign="right" sx={{ mt: '2.5rem' }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => onAppend({} as EventCapacity)}
          disabled={eventCapacityFiels.length >= 6}
        >
          Add more
        </Button>
      </Box>
    </Box>
  )
}

EventCapacityInput.defaultProps = { errors: undefined }

export default EventCapacityInput
