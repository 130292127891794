import * as yup from './common/yup-extended.d'

export const MultiLingualSchema = yup.default.object({
  en: yup.default.string().default(''),
  es: yup.default.string().default(''),
  fr: yup.default.string().default(''),
})

export type MultiLingual = yup.default.InferType<typeof MultiLingualSchema>

export const AddressSchema = yup.default.object({
  city: yup.default.string().required().default(''),
  country: yup.default.string().required().default(''),
  region: yup.default.string().default(''),
  postalCode: yup.default.string().default(''),
  suite: yup.default.string().default(''),
})

export type Address = yup.default.InferType<typeof AddressSchema>

export const PresentationDataSchema = yup.default.object({
  mimeType: yup.default.string().required().default(''),
  src: yup.default.string().required().default(''),
})

export type PresentationData = yup.default.InferType<typeof PresentationDataSchema>
