import React from 'react'
import { Menu, MenuItem, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { store } from '../../data/store'
import { appActions } from '../../data/store/app_store/app_store'

export default function LanguagePicker(): JSX.Element {
  const { i18n } = useTranslation()
  const menuStyle = { textDecoration: 'none' }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    store.dispatch(appActions.setLanguage(lang))
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const flag = (lang: string) => {
    let flagSvg
    switch (lang) {
      case 'en':
        flagSvg = 'United_States.svg'
        break
      case 'es':
        flagSvg = 'Spain.svg'
        break
      default:
        flagSvg = 'France.svg'
    }

    return <img src={`/assets/flags/${flagSvg}`} alt="Flag" style={{ maxHeight: 20, width: 30 }} />
  }
  return (
    <div>
      <Button
        id="btnUser"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Stack direction="row" spacing={1}>
          {flag(i18n.language)}
          <span>{i18n.language}</span>
        </Stack>
      </Button>
      <Menu id="language" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem style={menuStyle} onClick={() => changeLanguage('en')}>
          <Stack direction="row" spacing={1}>
            {flag('en')}
            <span>EN</span>
          </Stack>
        </MenuItem>
        <MenuItem style={menuStyle} onClick={() => changeLanguage('es')}>
          <Stack direction="row" spacing={1}>
            {flag('es')}
            <span>ES</span>
          </Stack>
        </MenuItem>
        <MenuItem style={menuStyle} onClick={() => changeLanguage('fr')}>
          <Stack direction="row" spacing={1}>
            {flag('fr')}
            <span>FR</span>
          </Stack>
        </MenuItem>
      </Menu>
    </div>
  )
}
