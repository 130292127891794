/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useMemo } from 'react'
import { BrowserRouter as Router, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import {
  Alert,
  Backdrop,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  LinkProps,
  Snackbar,
  ThemeProvider,
} from '@mui/material'
import { green } from '@mui/material/colors'
import AppHeader from './header/AppHeader'
import TopLevelRoutes from './Routes'
import { appActions, appSelector } from '../data/store/app_store/app_store'
import { useAppDispatch, useAppSelector } from '../data/store'
import { userActions, userSelectors } from '../data/store/user_store/user_store'
import SignIn from './sign/signIn'
import { auth } from '../firebase'

// eslint-disable-next-line
const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    return <RouterLink ref={ref} to={href} {...other} />
  },
)

export default function App(): JSX.Element | null {
  const dispatch = useAppDispatch()
  const useDarkMode = useAppSelector(appSelector.isDarkMode)
  const isLoading = useAppSelector(appSelector.isLoading)
  const successMessage = useAppSelector(appSelector.getMessage)

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
          MuiInputBase: {
            defaultProps: {
              disableInjectingGlobalStyles: true,
            },
          },
        },
        palette: {
          primary: { main: green.A400 },
          secondary: { main: '#FF5733' },
          background: {
            default: useDarkMode ? '#282c34' : '#ffffff',
          },
          mode: useDarkMode ? 'dark' : 'light',
        },
      }),
    [useDarkMode],
  )

  const setLoading = (value: boolean) => dispatch(appActions.setLoading(value))

  React.useEffect(() => {
    setLoading(true)
    auth.authStateReady().then(() => {
      setLoading(false)
      dispatch(userActions.setUserId(auth.currentUser?.uid))
    })
  }, [])

  const userId = useAppSelector(userSelectors.getUserId)

  useEffect(() => {
    dispatch(appActions.loadPreferences())
  }, [])

  const onToggleTheme = () => {
    dispatch(appActions.toogleDarkModePreference())
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isLoading && (
        <Router>
          {!userId && <SignIn onToggleTheme={onToggleTheme} />}
          {userId && (
            <>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              <Snackbar
                open={!!successMessage}
                autoHideDuration={3000}
                onClose={() => setLoading(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert onClose={() => setLoading(false)} severity="success" sx={{ width: '100%' }}>
                  {successMessage}
                </Alert>
              </Snackbar>
              <AppHeader onToggleTheme={onToggleTheme} />
              <Container maxWidth="xl" disableGutters>
                <TopLevelRoutes />
              </Container>
            </>
          )}
        </Router>
      )}
    </ThemeProvider>
  )
}
