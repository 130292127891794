import * as React from 'react'
import { AppBar, Container, Toolbar, IconButton, useTheme, Link } from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  ViewList as ViewListIcon,
  Brightness4 as Brightness4Icon,
  Checklist as ChecklistIcon,
} from '@mui/icons-material'
import LanguagePicker from './LanguagePicker'
import UserMenu from './UserMenu'
import AppMenu from './AppMenu'

type Props = {
  onToggleTheme: () => void
}

export default function Layout({ onToggleTheme }: Props): JSX.Element {
  const theme = useTheme()

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#282c34', color: 'white' }}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <Link href="/" style={{ marginRight: 15 }}>
            <img src="/assets/logo.png" alt="Abedy" style={{ width: '110px', height: '40px' }} />
          </Link>
          <Link href="/event">
            <IconButton>
              <ViewListIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
          <Link href="/">
            <IconButton>
              <ChecklistIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
          <span style={{ flexGrow: 1 }}>&nbsp;</span>
          <AppMenu />
          <UserMenu />
          <LanguagePicker />
          <IconButton onClick={onToggleTheme}>
            {theme.palette.mode === 'dark' ? (
              <Brightness7Icon sx={{ color: '#ffffff' }} />
            ) : (
              <Brightness4Icon sx={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
