export class Enum {
  static readonly EeventStatus = {
    // draft, active, live, started, ended, completed and canceled.
    Draft: 'draft',
    Active: 'active',
    Live: 'live',
    Started: 'started',
    Ended: 'ended',
    Completed: 'completed',
    Canceled: 'canceled',
  }
}

export const Constant = {
  emailRegex: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  ShouldBeAddressComponent: {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  },
}
