import * as yup from 'yup'

const integer = yup
  .number()
  .transform((_, val) => {
    if (val === '' || val === null || val === undefined) return null
    const n = Number(val)
    if (Number.isInteger(n)) return n
    return NaN
  })
  .default(null)
  .nullable()
  .typeError('common.errors.number')

const double = yup
  .number()
  .transform((_, val) => {
    if (val === '' || val === null || val === undefined) {
      return null
    }

    const n = Math.ceil(Number(val.toString().replace(',', '.')) * 100) / 100
    if (Number.isFinite(n)) return n

    return NaN
  })
  .default(null)
  .nullable()
  .typeError('common.errors.number')

export default {
  integer,
  double,
}
