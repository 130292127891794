import { TextField, InputBaseComponentProps, useTheme, Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import { SelectValueListItem } from '../../data/types/base'

interface Props extends Omit<InputBaseComponentProps, 'error'> {
  error?: FieldError
  content: SelectValueListItem[]
  disableNone?: boolean
}

const SingleSelect = React.forwardRef(function SimpleSelect(
  { content, error, disableNone, ...othersProps }: Props,
  ref,
) {
  const { t } = useTranslation()

  const theme = useTheme()

  return (
    <Box sx={{ mt: '2.5rem' }}>
      <Typography sx={{ mb: 2, ml: '2px', fontWeight: 500, wordBreak: 'break-word' }}>
        {othersProps.label} {othersProps.required && <span className="required">*</span>}
      </Typography>
      <TextField
        inputRef={ref}
        error={error !== undefined}
        variant="outlined"
        fullWidth
        select
        InputLabelProps={{ shrink: true }}
        SelectProps={{
          native: true,
          inputProps: { ...othersProps },
        }}
      >
        {/* style={{ display: 'none' }} hidden */}
        <option aria-label="None" value="" disabled={disableNone}>
          Please Select
        </option>
        {content.map((item) => (
          <option
            style={{
              color: item.label.toString().includes('**') ? theme.palette.grey[500] : 'none',
            }}
            key={item.value}
            value={item.value}
          >
            {t(item.label.toString())}
          </option>
        ))}
      </TextField>
    </Box>
  )
})

SingleSelect.defaultProps = {
  error: undefined,
  disableNone: undefined,
}
export default SingleSelect
