/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material'
import React from 'react'
import { FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form'
import InputTextField from './inputTextField'
import { Address } from '../../data/types/baseSchema'
import CountrySelect from './countrySelect'
import GoogleMapsPlaceSearche from './googlePlaceSearche'
import { PlaceDetails } from '../../data/types/googlePlace'

type Props = {
  path: string
  register: UseFormRegister<any>
  errors?: Merge<FieldError, FieldErrorsImpl<Address>>
  setPlaceDetails: (placeDetails: PlaceDetails | undefined) => void
}

function AddressInput({ register, errors, path, setPlaceDetails }: Props) {
  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12}>
        <GoogleMapsPlaceSearche
          {...register(`${path}.suite`)}
          label="Address"
          outLineText="Street Address"
          setPlaceDetails={setPlaceDetails}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputTextField outLineText="City" error={errors?.city} {...register(`${path}.city`)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CountrySelect label="" outLineText="Country" error={errors?.country} {...register(`${path}.country`)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputTextField outLineText="State" error={errors?.region} {...register(`${path}.region`)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputTextField outLineText="Postal code" error={errors?.postalCode} {...register(`${path}.postalCode`)} />
      </Grid>
    </Grid>
  )
}

AddressInput.defaultProps = {
  errors: undefined,
}

export default AddressInput
