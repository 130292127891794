import * as React from 'react'
import { Menu, MenuItem, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { auth } from '../../firebase'
import { userActions } from '../../data/store/user_store/user_store'
import { useAppDispatch } from '../../data/store'

export default function UserMenu(): JSX.Element {
  const { t } = useTranslation()
  const menuStyle = { textDecoration: 'none' }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useAppDispatch()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="btnUser"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        User name
      </Button>
      <Menu id="user-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          style={menuStyle}
          onClick={() => auth.signOut().then(() => dispatch(userActions.setUserId(auth.currentUser?.uid)))}
        >
          {t('logout')}
        </MenuItem>
      </Menu>
    </div>
  )
}
