import React from 'react'
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { Grid } from '@mui/material'
import { Event } from '../../../../data/types/eventSchema'
import InputTextField from '../../../components/inputTextField'
import AddressComponent from '../../../components/address'
import { PlaceDetails } from '../../../../data/types/googlePlace'
import { Constant } from '../../../../data/types/constances'

type Props = {
  register: UseFormRegister<Event>
  errors: FieldErrors<Event>
  setValue: UseFormSetValue<Event>
  getValues: UseFormGetValues<Event>
}
export default function Location({ register, errors, setValue, getValues }: Props) {
  const [placeDetails, setPlaceDetails] = React.useState<PlaceDetails | undefined>(undefined)
  const componentMapper = Constant.ShouldBeAddressComponent

  const setRegion = () => {
    componentMapper.region.forEach((regionMapper) => {
      placeDetails?.address_components.forEach((addr) => {
        if (!getValues('address.region') && addr.types.some((e) => e === regionMapper))
          setValue('address.region', addr.long_name)
      })
    })
  }

  const setCity = () => {
    componentMapper.city.forEach((cityMapper) => {
      placeDetails?.address_components.forEach((addr) => {
        if (!getValues('address.city') && addr.types.some((e) => e === cityMapper))
          setValue('address.city', addr.long_name)
      })
    })
  }

  const setPostalCode = () => {
    componentMapper.postal_code.forEach((postalCodeMapper) => {
      placeDetails?.address_components.forEach((addr) => {
        if (!getValues('address.postalCode') && addr.types.some((e) => e === postalCodeMapper))
          setValue('address.postalCode', addr.long_name)
      })
    })
  }

  const setCountry = () => {
    componentMapper.country.forEach((countryMapper) => {
      placeDetails?.address_components.forEach((addr) => {
        if (!getValues('address.country') && addr.types.some((e) => e === countryMapper))
          setValue('address.country', addr.long_name)
      })
    })
  }

  React.useEffect(() => {
    setValue('locationName', placeDetails?.name as string)
    setValue('location.latitude', placeDetails?.geometry.location.lat as number)
    setValue('location.longitude', placeDetails?.geometry.location.lng as number)

    setValue('address.postalCode', '')
    setValue('address.city', '')
    setValue('address.region', '')
    setValue('address.country', '')

    if (placeDetails) {
      setPostalCode()

      setCity()

      setRegion()

      setCountry()
    }
  }, [placeDetails])

  return (
    <>
      <AddressComponent path="address" errors={errors.address} register={register} setPlaceDetails={setPlaceDetails} />
      <InputTextField
        id="locationName"
        label="Event Place Name"
        {...register('locationName')}
        error={errors.locationName}
      />
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} md={6}>
          <InputTextField
            label=" Location Coordinates"
            outLineText="Latitude"
            error={errors.location?.latitude}
            {...register('location.latitude')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            outLineText="Longitude"
            error={errors.location?.longitude}
            {...register('location.longitude')}
          />
        </Grid>
      </Grid>
    </>
  )
}
