import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRootState } from '..'

interface UserState {
  userId: string | undefined
  userData: object | undefined
}

const initialState: UserState = {
  userId: undefined,
  userData: undefined,
}

export const userSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string | undefined>) => {
      state.userId = action.payload
    },
  },
})

export const userActions = userSlice.actions

export default userSlice.reducer

export const userSelectors = {
  getUserId: (state: TRootState) => state.user.userId,
}
