/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import * as React from 'react'
import {
  IconButton,
  useTheme,
  Typography,
  Container,
  Box,
  Grid,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Brightness7 as Brightness7Icon, Brightness4 as Brightness4Icon } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { browserLocalPersistence, browserSessionPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import { Sign, SignSchema } from '../../data/types/signInSchema'
import LanguagePicker from '../header/LanguagePicker'
import { auth } from '../../firebase'
import { useAppDispatch } from '../../data/store'
import { userActions } from '../../data/store/user_store/user_store'
import { reportErrorToConsole } from '../../utils/errorLogger'

type Props = {
  onToggleTheme: () => void
}

export default function SignIn({ onToggleTheme }: Props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<Sign>({
    mode: 'onBlur',
    resolver: yupResolver(SignSchema),
  })

  const onSubmit = (data: Sign) => {
    auth
      .setPersistence(data.rememberMe ? browserLocalPersistence : browserSessionPersistence)
      .then(() =>
        signInWithEmailAndPassword(auth, data.email, data.password)
          .then((userCredential) => {
            dispatch(userActions.setUserId(userCredential.user.uid))
          })
          .catch((error) => {
            reportErrorToConsole(error)
          }),
      )
      .catch((error) => {
        reportErrorToConsole(error)
      })
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ m: 1 }}>
            <img src="/assets/logo.png" alt="Abedy" width="auto" height="auto" />
          </Box>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
            <TextField
              error={!!errors.email}
              margin="normal"
              fullWidth
              id="email"
              label={t('sign.emailAddress')}
              autoComplete="email"
              autoFocus
              {...register('email')}
            />
            <TextField
              error={!!errors.password}
              margin="normal"
              fullWidth
              label={t('sign.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              {...register('password')}
            />
            <FormControlLabel
              style={{ whiteSpace: 'nowrap' }}
              control={<Checkbox value="remember" color="primary" />}
              label={t('sign.rememberMe')}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('sign.signIn')}
            </Button>
            <Grid container style={{ whiteSpace: 'nowrap' }}>
              <Grid item xs>
                <Link href="#" variant="body2" color={theme.palette.text.primary}>
                  {t('sign.forgotPassword')}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" color={theme.palette.text.primary}>
                  {t('sign.dontAccount')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Copyright */}
        <Grid container sx={{ mt: 8, mb: 4 }} alignItems="center" style={{ whiteSpace: 'nowrap' }}>
          <Grid item xs>
            <Typography variant="body2" color="text.secondary">
              {t('sign.copyright')} ©
              <Link color="inherit" href="#">
                {`Abedy ${new Date().getFullYear()}`}
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <LanguagePicker />
          </Grid>
          <Grid item>
            <IconButton onClick={onToggleTheme}>
              {theme.palette.mode === 'dark' ? (
                <Brightness7Icon sx={{ color: '#ffffff' }} />
              ) : (
                <Brightness4Icon sx={{ color: 'black' }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
