import { compareAsc } from 'date-fns'
import * as yup from './common/yup-extended.d'
import { AddressSchema, MultiLingualSchema, PresentationDataSchema } from './baseSchema'
import yupExtInt from './common/SchemaTypes'
import { Enum } from './constances'

// TODO For test
const abedyLogoUrl =
  'https://firebasestorage.googleapis.com/v0/b/abedy-2023-02-23.appspot.com/o/organization%2Fabedy_logo.png?alt=media&token=63ef4567-39ce-4c56-ac95-d6e86e36430d'

const randomImage = 'https://source.unsplash.com/random'

export const EventCapacitySchema = yup.default.object({
  ticketClass: MultiLingualSchema.required().when((value, schema) => {
    schema.fields.fr = yup.default.string().required()
    return schema
  }),
  capacityTotal: yupExtInt.integer.required().nonNullable(),
  capacitySold: yupExtInt.integer.required().default(0),
  ticketPrice: yupExtInt.double.required().nonNullable(),
})

export type EventCapacity = yup.default.InferType<typeof EventCapacitySchema>

export const InterestedSchema = yup.default.object({
  userId: yup.default.string().required(),
  image: yup.default.string().required(),
})

export const GuestSchema = yup.default.object({
  image: yup.default.string().default(abedyLogoUrl),
  fullName: yup.default.string().required(),
  role: MultiLingualSchema.required().when((value, schema) => {
    schema.fields.fr = yup.default.string().required()
    return schema
  }),
  title: MultiLingualSchema.required().when((value, schema) => {
    schema.fields.fr = yup.default.string().required()
    return schema
  }),
  existingUserId: yup.default.string(),
})

export type Guest = yup.default.InferType<typeof GuestSchema>

export const GeoPointSchema = yup.default.object({
  latitude: yup.default.number().required().default(undefined),
  longitude: yup.default.number().required().default(undefined),
})

export const EventSchema = yup.default.object({
  id: yup.default.string().nullable(),
  title: yup.default.string().required().max(80).default(''),
  image: yup.default.string().required().default(randomImage),
  summary: yup.default.string().required().max(150),
  capacity: yup.default
    .array(EventCapacitySchema)
    .required()
    .min(1)
    .max(6)
    .default([{} as EventCapacity]),
  status: yup.default.string().required().oneOf(Object.values(Enum.EeventStatus)).default('active'),
  currency: yup.default.string().required().default('CAD'),
  organizerId: yup.default.string().required().default('0'),
  categoryId: yup.default.string().required().default(''),
  startOn: yup.default.date().required().min(new Date()),
  endOn: yup.default
    .date()
    .required()
    .test('toLaterThanTo', 'dateToLaterThanDate', (value, sch) => {
      return compareAsc(value, sch.parent.startOn) === 1
    }),
  locationName: yup.default.string().required(),
  location: GeoPointSchema.required(),
  guests: yup.default
    .array(GuestSchema)
    .min(1)
    .required()
    .min(1)
    .default([{ title: { fr: '' } } as Guest]),
  address: AddressSchema.required(),
  publishedOn: yup.default.date().default(new Date()),
  presaleStartOn: yup.default.date().default(new Date()),
  presaleEndOn: yup.default.date().required().default(new Date()),
  // Optional value
  onlineOnly: yup.default.boolean().default(false),
  listed: yup.default.boolean().default(true),
  isShareable: yup.default.boolean().default(true),
  inviteOnly: yup.default.boolean().default(false),
  invitedByUserId: yup.default.string().nullable(), // TODO
  description: yup.default.string().nullable(),
  ageRestriction: yupExtInt.integer,
  eventUrl: yup.default.string().nullable(),
  terms: yup.default.string().nullable().max(150).default(''),
  sponsorIds: yup.default.array(yup.default.string()).nullable().default(null),
  subcategoryIds: yup.default.array(yup.default.string()).nullable().default(null),
  interested: yup.default.array(InterestedSchema).nullable().default(null),
  salesChannels: yup.default.array(yup.default.string()).nullable().default(null),
  presentationData: yup.default.array(PresentationDataSchema).nullable().default(null),
})

export type Event = yup.default.InferType<typeof EventSchema>
