import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import NotFoundPage from './error_pages/NotFoundPage'
import CreateEvent from './events/edit/createEvent'
import Events from './events/events'

export default function TopLevelRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/event/create-event" />} />
      <Route path="event">
        <Route path="" element={<Events />} />
        <Route path="create-event" element={<CreateEvent />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
