import React from 'react'
import { Container } from '@mui/material'

export default function NotFoundPage(): JSX.Element {
  return (
    <Container maxWidth="xl" disableGutters>
      There is nothing here!
    </Container>
  )
}
