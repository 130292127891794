import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { Delete as DeleteIcon, FilePresent as FilePresentIcon } from '@mui/icons-material'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '100%',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  whiteSpace: 'nowrap',
  width: '100%',
})

type props = {
  required?: boolean
}

const InputFileUpload = React.forwardRef(function InputFileUpload({ required: isRequired }: props, ref) {
  const [files, setFiles] = React.useState<File[]>([])
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false)
  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  React.useImperativeHandle(ref, () => ({
    getFiles() {
      return files
    },
  }))

  const pushFiles = (filesListes: FileList | null) => {
    if (filesListes)
      setFiles((filesList: File[]) => {
        for (let index = 0; index < filesListes.length; index += 1) {
          const element = filesListes[index]
          filesList.push(element)
        }

        return [...filesList]
      })
  }
  const dragEvents = {
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e)
      setIsDragOver(true)
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e)
      setIsDragOver(false)
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e)
      setIsDragOver(false)

      pushFiles(e.dataTransfer.files)
    },
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    pushFiles(event.target.files)
  }

  const removeFile = (index: number) => {
    setFiles((list: File[]) => {
      const tempFiles: File[] = []
      list.forEach((item, ind) => {
        if (ind !== index) tempFiles.push(item)
      })
      return tempFiles
    })
  }

  const theme = useTheme()
  return (
    <Box sx={{ mt: 3.5 }}>
      <Typography>Upload presentation data {isRequired && <span className="required">*</span>}</Typography>
      <Button
        component="label"
        {...dragEvents}
        sx={{
          mt: 1,
          mb: 1,
          border: '1px dashed',
          borderRadius: '4px',
          fontSize: '18px',
          height: '132px',
          width: '100%',
          minWidth: '258px',
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <CloudUploadIcon />
          <Typography>Upload a file</Typography>
          <Typography>{isDragOver ? 'Drop file here' : 'Drag and drop files here'}</Typography>
        </Stack>

        <VisuallyHiddenInput type="file" multiple onChange={handleChange} />
      </Button>

      {files.map((file, index) => (
        <Stack key={file.name} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            {file.type.includes('image/') && (
              <img
                id={`img_${index}`}
                width={25}
                height={25}
                sizes="small"
                src={`${URL.createObjectURL(file)}`}
                alt=""
                loading="lazy"
              />
            )}

            {!file.type.includes('image/') && <FilePresentIcon sx={{ fontSize: 25 }} />}
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {file.name}
            </Typography>
          </Stack>

          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              '&:hover': {
                color: theme.palette.error.main,
              },
            }}
            onClick={() => removeFile(index)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Stack>
      ))}
    </Box>
  )
})

InputFileUpload.defaultProps = { required: false }
export default InputFileUpload
