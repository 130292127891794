import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { Provider } from 'react-redux'

import './index.css'
import reportWebVitals from './reportWebVitals'
import { store } from './data/store'
import { appActions } from './data/store/app_store/app_store'
import App from './app/App'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'fr',
    returnNull: false,
    debug: false,
    supportedLngs: ['fr', 'en', 'es'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: 'locales/{{lng}}.json',
    },
  })
  .then(() => {
    store.dispatch(appActions.setLanguage(i18n.language))
  })

export default i18n

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
