/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'autosuggest-highlight/parse'
import { debounce } from '@mui/material/utils'
import { FieldError } from 'react-hook-form'
import { StandardTextFieldProps } from '@mui/material'
import InputTextField from './inputTextField'
import { PlaceDetails, PlaceType } from '../../data/types/googlePlace'

const GOOGLE_MAPS_API_KEY = 'AIzaSyB_u8c7x_0EFtuxZ3N1H4mN29b6QEjsJqE'

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
  outLineText?: string
  setPlaceDetails: (placeDetails: PlaceDetails | undefined) => void
}

export const GoogleMapsPlaceSearche = React.forwardRef(function GoogleMapsPlaceSearche(
  { error, outLineText, setPlaceDetails, ...othersProps }: Props,
  ref,
) {
  const [value, setValue] = React.useState<PlaceType | null>(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const loaded = React.useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      )
    }

    loaded.current = true
  }

  const getGeoPoint = (selectedPlace: PlaceType | null) => {
    if (!selectedPlace) {
      setPlaceDetails(undefined)
      return
    }

    const { google } = window as any
    function callback(place: any, status: any) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const details: PlaceDetails = {
          ...place,
          geometry: { location: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } },
        }
        setPlaceDetails(details)
      }
    }

    const request = {
      placeId: selectedPlace.place_id,
      fields: [
        'name',
        'formatted_address',
        'place_id',
        'reference',
        'rating',
        'formatted_phone_number',
        'geometry',
        'address_components',
      ],
    }

    const map = new google.maps.Map(document.createElement('div'))
    const service = new google.maps.places.PlacesService(map)
    service.getDetails(request, callback)
  }

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        ;(autocompleteService.current as any).getPlacePredictions(request, callback)
      }, 400),
    [],
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        getGeoPoint(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <InputTextField {...othersProps} {...params} error={error} outLineText={outLineText} inputRef={ref} />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part: any) => (
                  <Box key={part.highlight} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
})

GoogleMapsPlaceSearche.defaultProps = {
  error: undefined,
  outLineText: undefined,
}

export default GoogleMapsPlaceSearche
