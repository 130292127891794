import { Box, Button, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import {
  FieldArrayWithId,
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
} from 'react-hook-form'
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material'
import { Event, Guest } from '../../../../data/types/eventSchema'
import { SingleSelect, InputTextField } from '../../../components'

type Props = {
  errors?: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<Guest>> | undefined)[]>
  register: UseFormRegister<Event>
  guestsFiels: FieldArrayWithId<Event, 'guests', 'id'>[]
  onAppend: UseFieldArrayAppend<Event, 'guests'>
  onDelete: UseFieldArrayRemove
  allowDelete: boolean
}
function GuestInput({ errors, register, onDelete, allowDelete, guestsFiels, onAppend }: Props) {
  const theme = useTheme()
  return (
    <Box sx={{ mt: '2.5rem' }}>
      <Typography sx={{ mb: 0, ml: '2px', fontWeight: 500, wordBreak: 'break-word' }}>
        Guests <span className="required">*</span>
      </Typography>
      {guestsFiels.map((guest, index) => (
        <div key={guest.id}>
          {index > 0 && <Divider sx={{ mt: '2.5rem' }} />}

          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} md={4}>
              <SingleSelect
                label="Title"
                error={errors?.[index]?.title?.fr}
                {...register(`guests.${index}.title.fr`)}
                disableNone
                content={[
                  { value: 'Mr', label: 'Mr' },
                  { value: 'Mrs.', label: 'Mrs.' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={allowDelete ? 3.5 : 4}>
              <InputTextField
                label="Full Name"
                error={errors?.[index]?.fullName}
                {...register(`guests.${index}.fullName`)}
              />
            </Grid>
            <Grid item xs={12} md={allowDelete ? 3.5 : 4}>
              <InputTextField label="Role" error={errors?.[index]?.role?.fr} {...register(`guests.${index}.role.fr`)} />
            </Grid>

            {allowDelete && (
              <Grid item xs={12} md={1} textAlign="end">
                <IconButton
                  aria-label="delete"
                  sx={{
                    '&:hover': {
                      color: theme.palette.error.main,
                    },
                  }}
                  onClick={() => onDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </div>
      ))}
      <Box textAlign="right" sx={{ mt: '2.5rem' }}>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => onAppend({} as Guest)}>
          Add more
        </Button>
      </Box>
    </Box>
  )
}

GuestInput.defaultProps = { errors: undefined }

export default GuestInput
