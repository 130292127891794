import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import appReducer from './app_store/app_store'
import userReducer from './user_store/user_store'

export function InitializeStore() {
  return configureStore({
    reducer: {
      app: appReducer,
      user: userReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
  })
}

export const store = InitializeStore()

export type TRootState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<TAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector
