import { Box, StandardTextFieldProps, TextField, Typography } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
  outLineText?: string
}

const InputTextField = React.forwardRef(function InputTextField({ error, outLineText, ...othersProps }: Props, ref) {
  return (
    <Box sx={{ mt: '2.5rem' }}>
      <Typography sx={{ mb: 2, ml: '2px', fontWeight: 500, wordBreak: 'break-word' }}>
        {othersProps.label} {othersProps.required && <span className="required">*</span>}
      </Typography>
      <TextField
        inputRef={ref}
        {...othersProps}
        label={outLineText}
        error={error !== undefined}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      {/* {error && (
        <Alert sx={{ fontSize: '.75em', width: 'auto', padding: '.25em .5em .25em 1.625em' }} severity="error">
          {error?.message}
        </Alert>
      )} */}
    </Box>
  )
})

InputTextField.defaultProps = {
  error: undefined,
  outLineText: undefined,
}

export default InputTextField
