import * as React from 'react'
import Box from '@mui/material/Box'
import { FieldError } from 'react-hook-form'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { countries } from '../../data/types/countries'

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
  outLineText?: string
}

const CountrySelect = React.forwardRef(function CountrySelect({ error, outLineText, ...othersProps }: Props, ref) {
  return (
    <Autocomplete
      id="country-select-demo"
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          {...othersProps}
          label={outLineText}
          error={error !== undefined}
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
})

CountrySelect.defaultProps = {
  error: undefined,
  outLineText: undefined,
}

export default CountrySelect
