/* eslint-disable @typescript-eslint/no-explicit-any */
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc, DocumentReference, DocumentData } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { useAppDispatch } from './data/store'
import { appActions, error } from './data/store/app_store/app_store'
import { reportErrorToConsole } from './utils/errorLogger'

const firebaseConfig = {
  apiKey: 'AIzaSyB_u8c7x_0EFtuxZ3N1H4mN29b6QEjsJqE',
  appId: '1:54076292815:web:8593f8a08b510599da81ba',
  messagingSenderId: '54076292815',
  projectId: 'abedy-2023-02-23',
  authDomain: 'abedy-2023-02-23.firebaseapp.com',
  storageBucket: 'abedy-2023-02-23.appspot.com',
  measurementId: 'G-JGZDJQTGNF',
}

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export const db = getFirestore(app)

export const storage = getStorage(app)

export default app

const Action = () => {
  const actions = {
    dispatch: useAppDispatch(),
    setLoading: (value: boolean) => actions.dispatch(appActions.setLoading(value)),
    setSucessMessage: (message: string) => actions.dispatch(appActions.setSucessMessage(message)),
    setError: (errors: error) => actions.dispatch(appActions.setError(errors)),
  }

  return actions
}
export const setDocument = async (path: string, data: any) => {
  await addDoc(collection(db, path), data)
    .then((docRef: DocumentReference<any, DocumentData>) => {
      Action().setLoading(true)
      Action().setSucessMessage('Event created with success')
      return docRef.id
    })
    .catch((e: any) => {
      Action().setLoading(false)
      reportErrorToConsole(e)
      Action().setError({ code: e.code, message: e.message })
      throw e
    })
}
